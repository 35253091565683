import React from 'react'

export const NoTemplatesAvailable = ({ medications }) => {
    return medications.map(medication => (
        <div style={{ 
                width: '100%', 
                padding: '20px', 
                marginBottom: '20px', 
                background: 'rgba(255, 0, 0, .2)', 
                color: '#000',
                borderRadius: '5px', 
                display: 'flex',
                flexDirection: 'column',
                gap: '5px'
            }}
        >
            <strong>No template found for the following medication: {medication.drugName} {medication.dosage} { medication.DAW && `(DAW)`}</strong>
            <p>A ticket will be created for the admin team to add a new template upon the completion of this case. Continue prescribing manually. If this is a DAW prescription, please ensure you check "No Substitutions" when adding the prescription.</p>
            <div style={{ display: 'flex', gap: '20px' }}>
                <span>Quantity: {medication.pillCount}</span>
                <span>Refills: {medication.refills}</span>
                <span>Supply: {medication.daysSupply} days</span>
            </div>
        </div>
    ))
}