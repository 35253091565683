import React, { useEffect, memo } from 'react'
import { Spin, Typography, Radio } from 'antd'

import {
    TemplatesContainer,
    StyledSelect
} from '../styles'

const TemplateSelect = ({
    loading,
    templates,
    prescription,
    templateOwnerType,
    selectedTemplate,
    selectTemplateOwnerType,
    handleSelectTemplate,
}) => {

    const handleSelectTemplateOwnerType = ({ target }) => {
        selectTemplateOwnerType(target.value)
    }

    useEffect(() => {
        if (templates?.length && prescription._id) {
            handleSelectTemplate({
                prescription,
                template: templates[0]
            })
        } 
    }, [templates])

    const _handleSelectTemplate = val => {
        const template = templates?.find(template => template._id === val)
        handleSelectTemplate({
            prescription,
            template
        })
    }

    return (
        <Spin spinning={loading}>
            <TemplatesContainer>
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    marginBottom: '10px'
                }}> 
                    <Typography.Text>
                        <strong>Template Options</strong>
                    </Typography.Text>
                    <Radio.Group 
                        size='small'
                        value={templateOwnerType} 
                        onChange={handleSelectTemplateOwnerType}  
                    >
                    <Radio.Button size="small" value='admin'>Admin</Radio.Button>
                    <Radio.Button size="small" value='personal'>Personal</Radio.Button>
                   </Radio.Group>
                </div>
                <StyledSelect
                    value={selectedTemplate?.title || 'None Selected'}
                    options={templates?.map(template => ({ label: template.title, value: template._id}))} 
                    onChange={_handleSelectTemplate}
                />
            </TemplatesContainer>
            <hr style={{ margin: '10px 0'}}/>
        </Spin>
    )
}

export const MemoizedTemplateSelect = memo(TemplateSelect)

