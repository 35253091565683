import React from 'react'

import { 
    EditOutlined,
    DeleteOutlined 
} from '@ant-design/icons'

import { StyledButton } from '../../../../../Button'
export const Prescription = ({
    prescription,
    handleSelectPrescription,
    handleRemovePrescription,
    isAssignedProvider,
}) => {
    return (
        <div style={{
            width: '100%',
            padding: '20px',
            marginBottom: '20px',
            background: 'rgba(123, 173, 126, .15)',
            color: '#000',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px'
        }}
        >
            <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: 'calc(100% - 100px)' }}>
                        <strong style={{ color: '#7BAD7E', fontSize: '1.1rem' }}>{prescription.drugName || prescription.name}</strong>
                    </div>
                    {
                        isAssignedProvider && (
                            <div style={{ width: '100px', display: 'flex', justifyContent: 'flex-end', gap: '3px', }}>
                            <StyledButton size='small' Icon={EditOutlined} onClick={handleSelectPrescription} />
                            <StyledButton size='small' Icon={DeleteOutlined} onClick={handleRemovePrescription} color="red" />
                        </div>
                        )
                    }
                </div>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
                <span>{prescription.quantity} {prescription.quantityUnits}(s)</span>
                <span>Refills: {prescription.refills}</span>
                <span>Supply: {prescription.daysSupply} days</span>
            </div>
            <div><strong>Directions:</strong> {prescription.directions}</div>
            <div><strong>Pharmacy Notes:</strong> {prescription.pharmacyNotes || "None"}</div>
            { prescription.DAW && <div style={{ color: 'red'}}><strong>NO SUBSTITUTIONS</strong></div> }
        </div>
    )
}