import { useState } from 'react'
import { message } from 'antd'

import PhysicianAPI from "../../../../../../../axios/physician/PhysicianApi"
import { useDispenseUnits } from './useDispenseUnits'

function removeStrengthFromMedicationName(medicationName) {
    const pattern = /\s?\d+\.?\d*\s?(mg|ml|mg\/ml)\b$/;
    return medicationName.replace(pattern, '').trimStart();
}

function removeStrengthFromQuantity(inputStr) {
    const numericStr = inputStr.replace(/[^\d.]/g, '');
    const numericStrTrimmed = numericStr.trim();
    const numericValue = parseFloat(numericStrTrimmed);
    return numericValue;
}

export function usePrescriptionTemplates() {
    const [templates, setTemplates] = useState([])
    const [selectedTemplate, selectTemplate] = useState(null)
    const { getDispenseUnitById } = useDispenseUnits()

    const searchTemplates = async ({ topLevelFilters = {}, absoluteFilters = {}, rankedFilters = {} }) => {
        
        try {
            const { data: { templates } } = await PhysicianAPI.getTemplatesByCloseness({
                type: 'prescription',
                topLevelFilters,
                absoluteFilters,
                rankedFilters
            })
            setTemplates(templates)
            return templates
        } catch (e) {
            message.error("Error fetching prescription templates")
            console.log(e)
        } 
    }

    const templatizePrescription = async (requestedMedication) => {
            
        const templates = await searchTemplates({
            topLevelFilters: {
                ownerId: null,
                ...(requestedMedication.DAW && { DAW: requestedMedication.DAW }),
            },
            absoluteFilters: {
                drugName: removeStrengthFromMedicationName(requestedMedication.drugName),
                pseudonyms: requestedMedication.drugName,
            },
            rankedFilters: { 
                strength: requestedMedication.strength
            }
        })

        if (!templates?.[0]) {
            return null
        }

        const dispenseUnit = getDispenseUnitById(templates[0].medication.dispenseUnitId)

        const medication = {
            ...templates[0],
            ...templates[0].medication,
            ndc: templates[0].medication.ndc,
            refills: Number(requestedMedication.refills),
            daysSupply:Number(requestedMedication.daysSupply),
            schedule: Number(templates[0].schedule || 0),
            name: templates[0].medication.drugName,
            quantity: removeStrengthFromQuantity(requestedMedication.pillCount),
            quantityUnits: dispenseUnit?.Name,
            providePharmacyNotes: !!templates[0].pharmacyNotes,
            noSubstitutions: !!templates[0].DAW,
            ...(templates[0].medication.ingredients && { ingredients: templates[0].medication.ingredients }),
            _id: requestedMedication._id,
            startDate: new Date(),
            valid: false
        }

        return medication
    }

    const populateTemplateOptions = async (drugName, strength, DAW) => {
        const searchParams = {
            topLevelFilters: {
                ownerId: null,
                ...(DAW && { DAW: true }),
            },
            absoluteFilters: {
                drugName: removeStrengthFromMedicationName(drugName),
                pseudonyms: drugName,
            },
        }

        if (strength) {
            searchParams.rankedFilters = { 
                strength
            }
        }

        const templates = await searchTemplates(searchParams)
        setTemplates(templates)
    }

    const resetTemplateSearch = () => {
        setTemplates([])
        selectTemplate(null)
    }

    return { 
        templates, 
        selectedTemplate,
        searchTemplates,
        selectTemplate,
        resetTemplateSearch,
        templatizePrescription,
        populateTemplateOptions,
    };
}
