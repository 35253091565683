import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'

import { useProviderContext } from '../context/ProviderContext'

import TemplatesAPI from "../../../axios/templates/TemplateAPI"
import PhysicianAPI from "../../../axios/physician/PhysicianApi"

export function usePrescriptionTemplates({
    form,
    prescription,
    clearQueuedPrescription,
    saveQueuedPrescription,
    dispenseUnits,
    getDispenseUnitById,
    validateRx,
    setShowPharmacyNotesTextArea,
}) {
    const [templatesLoading, setTemplatesLoading] = useState(true)
    const [selectedTemplate, selectTemplate] = useState(null)
    const [templateOwnerType, selectTemplateOwnerType] = useState('admin')
    const [templates, setTemplates] = useState(null)
    const { provider } = useProviderContext()

    const searchTemplates = useCallback(async ({ topLevelFilters = {}, absoluteFilters = {}, rankedFilters = {} }) => {
        selectTemplate(null)
        setTemplates([])
        setTemplatesLoading(true)

        try {
            const { data: { templates } } = await PhysicianAPI.getTemplatesByCloseness({
                type: 'prescription',
                topLevelFilters,
                absoluteFilters,
                rankedFilters
            })

            const validTemplates = templates.filter(template => {
                return !!template.medication.dispensableDrugId || template.medication.strength?.toLowerCase() === 'compound'
            })

            message.success(`${validTemplates.length} template matches found.`)
            setTemplates(validTemplates)
            return validTemplates
        } catch (e) {
            message.error("Error fetching prescription templates")
            console.log(e)
        } finally {
            setTemplatesLoading(false)
        }
    }, [])

    function removeStrengthFromQuantity(inputStr) {
        const numericStr = inputStr.replace(/[^\d.]/g, '');
        const numericStrTrimmed = numericStr.trim();
        const numericValue = parseFloat(numericStrTrimmed);
        return numericValue;
    }

    const handleSelectTemplate = ({
        template,
        prescription
    }) => {
        if (prescription?._id) {

            clearQueuedPrescription && clearQueuedPrescription(prescription._id)
            selectTemplate(template)

            const dispenseUnit = getDispenseUnitById(template.medication.dispenseUnitId)

            const medication = {
                ...template,
                ...template.medication,
                name: template.medication.drugName,
                quantityUnits: dispenseUnit?.Name,
                providePharmacyNotes: !!template.pharmacyNotes,
                refills: Number(prescription.requestedMedication?.refills || 0),
                daysSupply: Number(prescription.requestedMedication?.daysSupply || 30),
                quantity: removeStrengthFromQuantity(prescription.requestedMedication?.pillCount || 0),
            }

            
            form.setFieldsValue(medication)
            setShowPharmacyNotesTextArea(medication.providePharmacyNotes)
            
            saveQueuedPrescription({ 
                ...medication, 
                _id: prescription._id,
                ...(template.medication.ingredients && { ingredients: templates[0].medication.ingredients })
            })

            validateRx()
        }
    }

    const preloadTemplate = async () => {
        if (prescription?.requestedMedication) {  
            const { requestedMedication } = prescription
            
            function removeStrengthFromMedicationName(medicationName) {
                const pattern = /\s?\d+\.?\d*\s?(mg|ml|mg\/ml)\b$/;
                return medicationName.replace(pattern, '').trimStart();
            }

            
            const templates = await searchTemplates({
                topLevelFilters: {
                    ownerId: templateOwnerType === 'personal' ? provider.userId : null,
                    ...(requestedMedication.DAW && { DAW: true })
                },
                absoluteFilters: {
                    drugName: removeStrengthFromMedicationName(requestedMedication.drugName),
                    pseudonyms: requestedMedication.drugName,
                },
                rankedFilters: { 
                    strength: requestedMedication.strength
                }
            })

            if (templates?.[0] && saveQueuedPrescription) {
                handleSelectTemplate(templates[0], prescription)
            } 
        }
    }

    

    const createTemplate = async ({
        title,
        pharmacyNotes,
        directions,
        teleMedicineCategoryIds,
        ...medication
    }) => {
        try {
            const payload = {
                title,
                pharmacyNotes,
                directions,
                teleMedicineCategoryIds,
                medication
            }

            await TemplatesAPI.create('prescription', payload);
            message.success('Template successfully created')
        } catch (e) {
            message.error('Error creating template')
            throw new Error(e)
        }
    }

    useEffect(() => {
        preloadTemplate()
    }, [dispenseUnits, templateOwnerType])

    return {
        selectTemplate,
        selectedTemplate,
        templatesLoading,
        templates,
        templateOwnerType,
        handleSelectTemplate,
        createTemplate,
        selectTemplateOwnerType,
    };
}
